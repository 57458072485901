//unset
ol li, ul li {
    padding-top: unset;
    padding-bottom: unset;
}
.subtitle {
    text-transform:  unset;
    color: unset;
    letter-spacing:  unset;
    font-weight:  unset;
    font-size: 12px;
}
.card {
    height: unset;
    flex-direction: unset;
}
dt {
    font-size:unset;
}
.modal-content {
    border-radius: 0;
    background-color: #f0f0f0;
    border: none;
}
.modal-dialog {
    margin: 16px;
    width: auto;
    max-width: 552px;
}